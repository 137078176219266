var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useAccessToken, useNavigate, createUseStyles, Button, Card, ConfirmDialog, Dialog, Skeleton, Toast, ROUTES, ReactRouterLink, SmallPaginator, } from "../../../../../projectdiction-common-react";
import { getDictionary, } from "@projectdiction/api-helper";
// @ts-ignore
import { useTranslation } from "react-i18next";
import AddToDictionaryDialog from "./AddToDictionaryDialog";
import { DictionaryItem } from "./DictionaryItem";
var useStyles = createUseStyles({
    root: {
        width: "100%",
        height: "100%",
    },
    card: {
        "& .p-card-body": {
            height: "100%",
        },
        "& .p-card-content": {
            height: "80% !important",
        },
    },
    buttonRoot: {},
});
var ReadingListViewItemDictionary = function (_a) {
    var readingItemId = _a.readingItemId, dictionaryId = _a.dictionaryId, updateParentView = _a.updateParentView, parentViewUpdater = _a.parentViewUpdater;
    var classes = useStyles();
    var _b = React.useState(), data = _b[0], setData = _b[1];
    var _c = React.useState(false), showAddDictionaryPopup = _c[0], _setShowAddDictionaryPopup = _c[1];
    var t = useTranslation().t;
    var token = useAccessToken();
    var setShowAddDictionaryPopup = function (val) {
        _setShowAddDictionaryPopup(val);
        updateParentView(!parentViewUpdater);
    };
    var toast = React.useRef();
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!dictionaryId) {
            return;
        }
        getDictionary(token, { dictionaryId: dictionaryId })
            .then(function (response) {
            setData(response.data.dictionary);
        })
            .catch(function (error) { return console.log("error", error); });
    }, [dictionaryId, token, parentViewUpdater]);
    if (!data || !dictionaryId) {
        return (_jsxs("div", __assign({ className: "flex flex-column w-9 h-full" }, { children: [_jsx(Skeleton, { width: "100%", height: "2rem" }), _jsx(Skeleton, { width: "100%", height: "15rem" })] })));
    }
    var Footer = function () {
        return (_jsxs("div", __assign({ className: "flex justify-content-center gap-3" }, { children: [_jsx(ReactRouterLink, __assign({ target: "_blank", to: ROUTES.VOCABULARY.DICTIONARY.STUDY(dictionaryId) }, { children: _jsx(Button, { label: t("vocabulary.buttons.study") }) })), _jsx(Button, { label: t("vocabulary.buttons.add_item"), onClick: function () {
                        setShowAddDictionaryPopup(true);
                    } })] })));
    };
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(Dialog, __assign({ header: t("vocabulary.titles.add_to_dictionary"), visible: showAddDictionaryPopup, style: {
                    width: "50vw",
                }, onHide: function () { return setShowAddDictionaryPopup(false); } }, { children: _jsx(AddToDictionaryDialog, { word: "", readingItemId: readingItemId, dialogVisible: showAddDictionaryPopup, setDialogVisible: setShowAddDictionaryPopup, updateParentView: updateParentView, toast: toast }) })), _jsx(Card, __assign({ title: data.label, footer: _jsx(Footer, {}), className: "h-full ".concat(classes.card, " shadow-2") }, { children: _jsx(SmallPaginator, { items: data.items, mapFunction: function (dictionaryItem, index) {
                        return (_jsx(DictionaryItem, { readingItemId: readingItemId, updateParentView: updateParentView, parentViewUpdater: parentViewUpdater, toast: toast, dictionaryId: dictionaryId, dictionaryItemId: dictionaryItem._id, word: dictionaryItem.word, meaning: dictionaryItem.meaning }, index));
                    } }) })), _jsx(Toast, { ref: toast }), _jsx(ConfirmDialog, {})] })));
};
export default ReadingListViewItemDictionary;
