var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, Card, IconField, InputIcon, InputText,
// @ts-ignore
 } from "@projectdiction/common-react";
export function SmallPaginator(_a) {
    var _this = this;
    var items = _a.items, mapFunction = _a.mapFunction, fetchFunction = _a.fetchFunction, searchFunction = _a.searchFunction, filterByAlphabetFunction = _a.filterByAlphabetFunction;
    var _b = React.useState(items), paginatedItems = _b[0], setPaginatedItems = _b[1];
    var _c = React.useState(paginatedItems.slice(0, 10)), currentItems = _c[0], _setCurrentItems = _c[1];
    var _d = React.useState(0), alphabet = _d[0], setAlphabet = _d[1];
    var _e = React.useState(""), searchText = _e[0], setSearchText = _e[1];
    var _f = React.useState({
        currentPage: 1,
        numberOfItems: 10,
        totalPages: Math.ceil(paginatedItems.length / 10),
    }), pagination = _f[0], setPagination = _f[1];
    var _g = React.useState({
        currentPage: 1,
        numberOfItems: 10,
    }), sliderValues = _g[0], setSliderValues = _g[1];
    var _h = React.useState(false), loading = _h[0], setLoading = _h[1];
    var _j = React.useState(false), searching = _j[0], setSearching = _j[1];
    function setCurrentItems(items) {
        if (fetchFunction) {
            setLoading(true);
            fetchFunction(items).then(function (response) {
                _setCurrentItems(response);
                setLoading(false);
            });
            return;
        }
        _setCurrentItems(items);
    }
    React.useEffect(function () {
        setCurrentItems(paginatedItems.slice((pagination.currentPage - 1) * pagination.numberOfItems, pagination.currentPage * pagination.numberOfItems));
    }, [pagination.currentPage, pagination.numberOfItems, paginatedItems]);
    function reset() {
        setPagination({
            currentPage: 1,
            numberOfItems: 10,
            totalPages: Math.ceil(items.length / 10),
        });
        setSliderValues({
            currentPage: 1,
            numberOfItems: 10,
        });
        setCurrentItems(items.slice((pagination.currentPage - 1) * pagination.numberOfItems, pagination.currentPage * pagination.numberOfItems));
        setAlphabet(0);
        setSearchText("");
    }
    function search(searchText) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!searchFunction) {
                            response = items.filter(function (item) {
                                return item.word.toLowerCase().includes(searchText.toLowerCase()) ||
                                    item.meaning.toLowerCase().includes(searchText.toLowerCase());
                            });
                        }
                        if (!searchFunction) return [3 /*break*/, 2];
                        setSearching(true);
                        return [4 /*yield*/, searchFunction(searchText)];
                    case 1:
                        response = _a.sent();
                        setSearching(false);
                        _a.label = 2;
                    case 2:
                        setPaginatedItems(response);
                        setPagination(function (prevPagination) { return (__assign(__assign({}, prevPagination), { currentPage: 1, totalPages: Math.ceil(response.length / pagination.numberOfItems) })); });
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx(Card, __assign({ pt: {
            root: { className: "card-root-with-header-footer" },
            body: { className: "" },
            header: {
                className: "w-full flex flex-wrap justify-content-center align-items-center",
            },
            content: {
                className: "w-full p-0 flex container justify-content-center flex-wrap animate__animated animate__fadeIn",
            },
            footer: {
                className: "flex justify-content-center align-items-center",
                style: { fontSize: "small" },
            },
        }, header: _jsx(_Fragment, { children: _jsxs(IconField, __assign({ className: "ml-4", iconPosition: "right" }, { children: [_jsx(InputText, { placeholder: "Search", value: searchText, onChange: function (e) {
                            setSearchText(e.target.value);
                            var response = items.filter(function (item) {
                                return item.word
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase()) ||
                                    item.meaning
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                            });
                            setPaginatedItems(response);
                            setPagination(function (prevPagination) { return (__assign(__assign({}, prevPagination), { currentPage: 1, totalPages: Math.ceil(response.length / pagination.numberOfItems) })); });
                        }, onKeyPress: function (e) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (e.key === "Enter") {
                                    search(searchText);
                                }
                                return [2 /*return*/];
                            });
                        }); } }), _jsx(InputIcon, { className: "pi ".concat(searching
                            ? "pi-spinner pi-spin"
                            : searchText
                                ? "pi-times"
                                : "pi-search"), onClick: function () {
                            if (searchText) {
                                setSearchText("");
                                search("");
                            }
                        } })] }), "search") }), footer: _jsxs(_Fragment, { children: [_jsx(Button, { size: "small", icon: "pi pi-arrow-left", onClick: function () {
                        setPagination(function (prevPagination) { return (__assign(__assign({}, prevPagination), { currentPage: pagination.currentPage - 1 })); });
                        setSliderValues(function (prevSliderValues) { return (__assign(__assign({}, prevSliderValues), { currentPage: pagination.currentPage - 1 })); });
                    }, disabled: pagination.currentPage === 1, className: "ml-3" }), _jsx(Button, { size: "small", icon: "pi pi-arrow-right", className: "ml-3", onClick: function () {
                        setPagination(function (prevPagination) { return (__assign(__assign({}, prevPagination), { currentPage: pagination.currentPage + 1 })); });
                        setSliderValues(function (prevSliderValues) { return (__assign(__assign({}, prevSliderValues), { currentPage: pagination.currentPage + 1 })); });
                    }, disabled: pagination.currentPage === pagination.totalPages })] }) }, { children: loading ? (_jsx("div", __assign({ className: "flex justify-content-center align-items-center w-full h-full" }, { children: _jsx("i", { className: "pi pi-spinner pi-spin text-4xl" }) }))) : (currentItems.map(mapFunction)) })));
}
