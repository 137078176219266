var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { InputText } from "primereact/inputtext";
export function InplaceEditable(_a) {
    var text = _a.text, updateText = _a.updateText, classes = _a.classes;
    var root = classes.root, display = classes.display, content = classes.content;
    var _b = React.useState(false), showInput = _b[0], setShowInput = _b[1];
    var _c = React.useState(), input = _c[0], setInput = _c[1];
    React.useEffect(function () {
        setInput(text);
    }, [text]);
    var cancelChange = function () {
        setShowInput(false);
        setInput(text);
    };
    var acceptChange = function () {
        setShowInput(false);
        updateText(input);
    };
    return (_jsx("div", __assign({ className: root }, { children: !showInput ? (_jsx("div", __assign({ className: "flex h-2rem " + content, style: { cursor: "text" }, onClick: function () { return setShowInput(true); } }, { children: input }))) : (_jsx("div", __assign({ className: "flex h-2rem" }, { children: _jsx(InputText, { value: input, placeholder: "Course Title", autoFocus: true, onFocus: function (e) {
                    e.target.setSelectionRange(0, e.target.value.length);
                }, onBlur: function () { return acceptChange(); }, onChange: function (e) { return setInput(e.target.value); }, onKeyDown: function (e) {
                    if (e.code === "Escape") {
                        cancelChange();
                    }
                    if (e.code === "Enter") {
                        acceptChange();
                    }
                } }) }))) })));
}
