var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createUseStyles, } from "../../../../../projectdiction-common-react";
var useStyles = createUseStyles({
    wordSpan: {
        cursor: "cell",
    },
});
export default function ReadingItemWordSpan(_a) {
    var word = _a.word, wordClicked = _a.wordClicked;
    var classes = useStyles();
    return (_jsx(_Fragment, { children: _jsxs("span", __assign({ className: "".concat(classes.wordSpan, " ml-1 hover:text-blue-500"), onClick: function () {
                var _a;
                wordClicked(((_a = word === null || word === void 0 ? void 0 : word.props) === null || _a === void 0 ? void 0 : _a.children) || word);
            } }, { children: [word, " "] })) }));
}
