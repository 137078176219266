var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useParams, Dialog, } from "../../../../../projectdiction-common-react";
import AddToDictionaryPopUp from "./AddToDictionaryPopUp";
export default function AddToDictionaryDialog(_a) {
    var readingItemId = _a.readingItemId, dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, word = _a.word, _b = _a.meaning, meaning = _b === void 0 ? "" : _b, updateParentView = _a.updateParentView, toast = _a.toast, _c = _a.wordId, wordId = _c === void 0 ? undefined : _c;
    var params = useParams();
    return (_jsx(Dialog, __assign({ header: "Add to dictionary", visible: dialogVisible, style: { width: "50vw" }, onHide: function () { return setDialogVisible(false); } }, { children: _jsx(AddToDictionaryPopUp, { readingItemId: readingItemId, word: word, meaning: meaning, setDialogVisible: setDialogVisible, toast: toast, updateParentView: updateParentView, wordId: wordId }) })));
}
