var ROUTES = {
    DEFAULT: "/",
    HOME: "/",
    LOGIN: "/user/login",
    SIGNUP: "/user/signup",
    SETTINGS: "/settings",
    READING: {
        HOME: "/reading",
        READING_LIST: {
            HOME: "/reading/readingList",
            ADD: "/reading/readingList/add",
            VIEW: function (id) {
                return id ? "/reading/readingList/view/".concat(id) : "/reading/readingList/view";
            },
            COPY: function (id) {
                return id ? "/reading/readingList/copy/".concat(id) : "/reading/readingList/copy";
            },
        },
        CATEGORIES: {
            HOME: "/reading/categories",
        },
    },
    WRITING: {
        HOME: "/writing",
    },
    SPEAKING: {
        HOME: "/speaking",
    },
    LISTENING: {
        HOME: "/listening",
    },
    GRAMMAR: {
        HOME: "/grammar",
    },
    VOCABULARY: {
        HOME: "/vocabulary",
        HOME_ID: "/vocabulary/:id",
        DICTIONARY: {
            HOME: function (id) {
                return id ? "/vocabulary/dictionary/".concat(id) : "/vocabulary/dictionary";
            },
            STUDY: function (id) {
                return id
                    ? "/vocabulary/dictionary/study/".concat(id)
                    : "/vocabulary/dictionary/study";
            },
            COPY: function (id) {
                return id
                    ? "/vocabulary/dictionary/copy/".concat(id)
                    : "/vocabulary/dictionary/copy";
            },
        },
        CATEGORIES: {
            HOME: "/vocabulary/categories",
        },
    },
    EXERCISES: {
        HOME: "/exercises",
        FILLBLANK: {
            HOME: "/exercises/fillblank",
            CREATOR: {
                HOME: "/exercises/fillblank/creator",
                FORM: "/exercises/fillblank/form",
                READING_ITEM: function (id) {
                    return id
                        ? "/exercises/fillblank/readingItem/".concat(id)
                        : "/exercises/fillblank/readingItem";
                },
                READING_ITEM_ID: "/exercises/fillblank/readingItem/:id",
            },
            SOLVE: function (id) {
                return id ? "/exercises/fillblank/solve/".concat(id) : "/exercises/fillblank/solve";
            },
            SOLVE_ID: "/exercises/fillblank/solve/:id",
        },
    },
    COURSES: {
        HOME: "/courses",
        CREATOR: {
            HOME: "/courses/creator",
            COURSE_ID: "/courses/creator/:courseId",
            COURSE: function (id) {
                return id ? "/courses/creator/".concat(id) : "/courses/creator";
            },
            SCREEN_ID: "/courses/creator/:courseId/screen/:screenId",
            SCREEN: function (courseId, screenId) {
                return "/courses/creator/".concat(courseId, "/screen/").concat(screenId);
            },
            EDIT_ID: "/courses/creator/edit/:courseId",
            EDIT: function (id) {
                return id ? "/courses/creator/edit/".concat(id) : "/courses/creator/edit";
            },
        },
        VIEWER: {
            HOME: "/courses/viewer",
            COURSE_ID: "/courses/viewer/:courseId",
            COURSE: function (id) { return "/courses/viewer/".concat(id); },
            SCREEN_ID: "/courses/viewer/:courseId/screen/:screenId",
            SCREEN: function (courseId, screenId) {
                return "/courses/viewer/".concat(courseId, "/screen/").concat(screenId);
            },
        },
    },
};
export { ROUTES };
