// @ts-ignore
import React from "react";
import { singletonHook } from "react-singleton-hook";
import { getFromLocalStorage, doesExistInLocalStorage, } from "../helperFunctions";
import LOCAL_STORAGE_ITEMS from "../static_data/localStorageItems";
var token;
if (doesExistInLocalStorage(LOCAL_STORAGE_ITEMS.TOKEN)) {
    token = getFromLocalStorage(LOCAL_STORAGE_ITEMS.TOKEN);
    token = token.accessToken;
}
var globalSetAccessToken = function () {
    throw new Error("useAccessToken is singleton and read only");
};
function useAccessUserTokenImpl() {
    var _a = React.useState(token), accessToken = _a[0], setAccessToken = _a[1];
    React.useEffect(function () { }, []);
    globalSetAccessToken = setAccessToken;
    return accessToken;
}
export var setAccessTokenState = function (token) {
    // @ts-ignore
    return globalSetAccessToken(token);
};
export var useAccessToken = singletonHook(token, useAccessUserTokenImpl);
