var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { addWordToReadingItemDictionary, lemmatize, translate, updateWordInDictionary,
// @ts-ignore
 } from "@projectdiction/api-helper";
import { useAccessToken, useUserPreferences, createUseStyles, Button, Divider, InputText, } from "../../../../../projectdiction-common-react";
var useAddToDictionaryPopUpStyles = createUseStyles({
    root: {},
    buttonRoot: {
        display: "flex",
        justifyContent: "center",
    },
});
var AddToDictionaryPopUp = function (_a) {
    var readingItemId = _a.readingItemId, word = _a.word, _b = _a.meaning, meaning = _b === void 0 ? "" : _b, setDialogVisible = _a.setDialogVisible, toast = _a.toast, updateParentView = _a.updateParentView, _c = _a.wordId, wordId = _c === void 0 ? undefined : _c;
    var _d = React.useState(word), wordInput = _d[0], setWordInput = _d[1];
    var _e = React.useState(meaning), meaningInput = _e[0], setMeaningInput = _e[1];
    var _f = React.useState(true), meaningDisabled = _f[0], setMeaningDisabled = _f[1];
    var _g = React.useState(), lemma = _g[0], setLemma = _g[1];
    var _h = React.useState(), lemmaTranslation = _h[0], setLemmaTranslation = _h[1];
    var classes = useAddToDictionaryPopUpStyles();
    var token = useAccessToken();
    var userPreferences = useUserPreferences();
    React.useEffect(function () {
        if (!userPreferences)
            return;
        lemmatize(wordInput, userPreferences.languages.studyLanguage)
            .then(function (response) {
            setLemma(response.lemmas[0]);
            translate(response.lemmas[0], userPreferences.languages.studyLanguage, userPreferences.languages.knownLanguage)
                .then(function (response) {
                setLemmaTranslation(response.translation);
            })
                .catch(function (error) {
                console.error(error);
            });
        })
            .catch(function (error) {
            console.error(error);
        });
    }, [word, wordInput]);
    React.useEffect(function () {
        if (wordId) {
            setMeaningDisabled(false);
            return;
        }
        if (!userPreferences)
            return;
        translate(wordInput, userPreferences.languages.studyLanguage, userPreferences.languages.knownLanguage)
            .then(function (response) {
            setMeaningInput(response.translation);
            setMeaningDisabled(false);
        })
            .catch(function (error) {
            console.error(error);
        });
    }, [wordInput]);
    return (_jsx("div", __assign({ className: classes.root }, { children: _jsxs("form", __assign({ onSubmit: function (e) {
                e.preventDefault();
            } }, { children: [_jsxs("div", __assign({ className: "fluid" }, { children: [_jsxs("div", { children: [_jsx("b", { children: "Lemma:" }), " ", lemma !== null && lemma !== void 0 ? lemma : "Lemmatizing...", " ", _jsx("i", { children: lemmaTranslation !== null && lemmaTranslation !== void 0 ? lemmaTranslation : "Translating..." })] }), _jsx(Divider, {}), _jsx(InputText, { value: wordInput, onChange: function (e) { return setWordInput(e.target.value); }, placeholder: "Word", className: "w-full" }), _jsx(Divider, {}), _jsx(InputText, { value: meaningDisabled ? "Translating..." : meaningInput, onChange: function (e) { return setMeaningInput(e.target.value); }, placeholder: "Meaning", disabled: meaningDisabled, className: "w-full" })] })), _jsx(Divider, {}), _jsx("div", __assign({ className: classes.buttonRoot }, { children: _jsx(Button, { type: "submit", label: wordId ? "Update" : "Add", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            function add() {
                                return __awaiter(this, void 0, void 0, function () {
                                    var response, e_1, isSuccess, severity;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                _a.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, addWordToReadingItemDictionary(token, {
                                                        readingItemId: readingItemId,
                                                        word: wordInput,
                                                        meaning: meaningInput,
                                                    })];
                                            case 1:
                                                response = _a.sent();
                                                return [3 /*break*/, 3];
                                            case 2:
                                                e_1 = _a.sent();
                                                response = "error";
                                                return [3 /*break*/, 3];
                                            case 3:
                                                if (typeof response === "object") {
                                                    isSuccess = response.status === 200;
                                                    severity = isSuccess ? "success" : "error";
                                                    toast.current.show({
                                                        severity: severity,
                                                        summary: response.data.message,
                                                        detail: response.error,
                                                    });
                                                    setDialogVisible(false);
                                                }
                                                else {
                                                    toast.current.show({
                                                        severity: "error",
                                                        summary: "Word Not Saved!",
                                                        detail: "Couldn't store the word in dictionary of current reading Item",
                                                    });
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                });
                            }
                            function update() {
                                return __awaiter(this, void 0, void 0, function () {
                                    var response, isSuccess, severity, summary;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, updateWordInDictionary(token, {
                                                    wordId: wordId,
                                                    word: wordInput,
                                                    meaning: meaningInput,
                                                })];
                                            case 1:
                                                response = _a.sent();
                                                if (typeof response === "object") {
                                                    isSuccess = response.status === 200;
                                                    severity = isSuccess ? "success" : "error";
                                                    summary = isSuccess
                                                        ? "Word updated successfully"
                                                        : "Word update failed";
                                                    toast.current.show({
                                                        severity: severity,
                                                        summary: summary,
                                                        detail: response.message,
                                                    });
                                                    setDialogVisible(false);
                                                }
                                                else {
                                                    toast.current.show({
                                                        severity: "error",
                                                        summary: "Word update failed",
                                                        detail: "Couldn't update the word in dictionary",
                                                    });
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                });
                            }
                            return __generator(this, function (_a) {
                                if (wordId) {
                                    update();
                                }
                                else {
                                    add();
                                }
                                setTimeout(function () {
                                    updateParentView();
                                }, 0);
                                return [2 /*return*/];
                            });
                        }); } }) }))] })) })));
};
export default AddToDictionaryPopUp;
