var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Card } from "../../primereact-components";
import { NavigationCard } from "./card/NavigationCard";
export var NavigationGrid = function (_a) {
    var title = _a.title, data = _a.data;
    return (_jsx(Card, __assign({ title: title, pt: {
            root: {
                className: "card-root-with-header-footer",
            },
            body: {
                className: "",
            },
            title: {
                className: "flex justify-content-center",
            },
            content: {
                className: "flex flex-wrap justify-content-center gap-4",
            },
        } }, { children: data.map(function (item, index) {
            return (_jsx(NavigationCard, { title: item.title, route: item.route, description: item.description, label: item.label }, index));
        }) })));
};
