import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Card, ROUTES, useUserLoggedIn, useNavigate, } from "../../projectdiction-common-react";
export function CheckLoginWrapper(_a) {
    var children = _a.children;
    var userLoggedIn = useUserLoggedIn();
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!userLoggedIn) {
            setTimeout(function () {
                navigate(ROUTES.LOGIN);
            }, 3000);
        }
    }, [userLoggedIn]);
    if (!userLoggedIn) {
        return (_jsxs(Card, { children: [_jsx("h1", { children: "Not logged in" }), _jsx("h2", { children: "Redirecting to login page in 3 seconds" })] }));
    }
    return children;
}
