var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getAllDictionaryCategories,
// @ts-ignore
 } from "@projectdiction/api-helper";
import { useAccessToken, useUserPreferences } from "../../hooks";
import { Chip } from "primereact/chip";
import { Card } from "primereact/card";
import PaginatedDictionaries from "./PaginatedDictionaries";
export function DictionaryCategories(_a) {
    var onSingleItemClick = _a.onSingleItemClick;
    var _b = React.useState(), categories = _b[0], setCategories = _b[1];
    var accessToken = useAccessToken();
    var userPreferences = useUserPreferences();
    var _c = React.useState(0), categoryIndex = _c[0], setCategoryIndex = _c[1];
    React.useEffect(function () {
        if (!accessToken || !userPreferences)
            return;
        getAllDictionaryCategories(accessToken, userPreferences.languages.studyLanguage, userPreferences.languages.knownLanguage, ["default", "frequency"])
            .then(function (response) {
            setCategories(response.data.categories);
        })
            .catch(function (error) {
            console.error(error);
        });
    }, [accessToken, userPreferences]);
    if (!categories)
        return null;
    return (_jsx(Card, __assign({ header: categories.map(function (category, index) { return (_jsx(Chip, { label: category.title, className: "m-1 cursor-pointer " +
                (index === categoryIndex ? "bg-primary text-white" : ""), onClick: function () { return setCategoryIndex(index); } }, category._id)); }), pt: {
            root: { className: "h-full p-0" },
            body: { className: "h-full p-0" },
            content: { className: "h-full p-0" },
        } }, { children: categories[categoryIndex] && (_jsx(PaginatedDictionaries, { dictionaryIds: categories[categoryIndex].dictionaries, onSingleItemClick: onSingleItemClick }, categoryIndex)) })));
}
