var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { confirmDialog, useAccessToken, createUseStyles, Button, Card, Dialog, } from "../../../../../projectdiction-common-react";
import { deleteWordFromDictionary, // @ts-ignore
 } from "@projectdiction/api-helper";
// @ts-ignore
import { useTranslation } from "react-i18next";
import AddToDictionaryDialog from "./AddToDictionaryDialog";
var useStyles = createUseStyles({
    card: {
        "& .p-card-body": {
            height: "100%",
        },
        "& .p-card-content": {
            height: "80% !important",
        },
    },
});
export var DictionaryItem = function (_a) {
    var dictionaryId = _a.dictionaryId, dictionaryItemId = _a.dictionaryItemId, word = _a.word, meaning = _a.meaning, updateParentView = _a.updateParentView, parentViewUpdater = _a.parentViewUpdater, toast = _a.toast, readingItemId = _a.readingItemId;
    var classes = useStyles();
    var _b = React.useState(""), wordInput = _b[0], setWordInput = _b[1];
    var _c = React.useState(""), meaningInput = _c[0], setMeaningInput = _c[1];
    var _d = React.useState(), currentWordId = _d[0], setCurrentWordId = _d[1];
    var _e = React.useState(false), showUpdateWordPopup = _e[0], _setShowUpdateWordPopup = _e[1];
    var t = useTranslation().t;
    var token = useAccessToken();
    var setShowUpdateWordPopup = function (val) {
        _setShowUpdateWordPopup(val);
        updateParentView(!parentViewUpdater);
    };
    var InnerButtonUpdate = function () {
        var updateClick = function () {
            setWordInput(word);
            setMeaningInput(meaning);
            setCurrentWordId(dictionaryItemId);
            setShowUpdateWordPopup(true);
        };
        return _jsx(Button, { icon: "pi pi-pencil", onClick: updateClick });
    };
    var InnerButtonDelete = function () {
        var deleteClick = function () {
            confirmDialog({
                message: t("vocabulary.subtitles.delete_word", {
                    word: word,
                }),
                header: t("vocabulary.titles.delete_word"),
                icon: "pi pi-exclamation-triangle",
                acceptLabel: t("texts.yes"),
                rejectLabel: t("texts.no"),
                accept: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response, isSuccess, severity, summary;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, deleteWordFromDictionary(token, {
                                    dictionaryId: dictionaryId,
                                    wordId: dictionaryItemId,
                                })];
                            case 1:
                                response = _a.sent();
                                if (typeof response === "object") {
                                    isSuccess = response.status === 200;
                                    severity = isSuccess ? "success" : "error";
                                    summary = isSuccess
                                        ? t("vocabulary.toasts.summaries.word_delete_success")
                                        : t("vocabulary.toasts.summaries.word_delete_fail");
                                    // @ts-ignore
                                    toast.current.show({
                                        severity: severity,
                                        summary: summary,
                                        detail: response.message,
                                    });
                                    updateParentView(!parentViewUpdater);
                                }
                                else {
                                    // @ts-ignore
                                    toast.current.show({
                                        severity: "error",
                                        summary: t("vocabulary.toasts.summaries.word_delete_fail"),
                                        detail: t("vocabulary.toasts.details.word_delete_fail"),
                                    });
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            });
        };
        return (_jsx(Button, { icon: "pi pi-trash", className: "p-button-danger ml-3", onClick: deleteClick }));
    };
    return (_jsxs("div", __assign({ className: "col-12 xl:col-6" }, { children: [_jsx(Dialog, __assign({ header: t("vocabulary.titles.update_word"), visible: showUpdateWordPopup, style: {
                    width: "50vw",
                }, onHide: function () { return setShowUpdateWordPopup(false); } }, { children: _jsx(AddToDictionaryDialog, { readingItemId: readingItemId, wordId: currentWordId, word: wordInput, meaning: meaningInput, dialogVisible: showUpdateWordPopup, setDialogVisible: setShowUpdateWordPopup, updateParentView: updateParentView, toast: toast }) })), _jsx(Card, { title: word, subTitle: meaning, className: classes.card, footer: _jsxs("div", __assign({ className: "flex justify-content-end" }, { children: [_jsx(InnerButtonUpdate, {}), _jsx(InnerButtonDelete, {})] })) })] })));
};
