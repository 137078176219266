var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var STYLES_FLEX_CENTER_CENTER = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
export var STYLES_CARD = {
    "& .p-card-body": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        "& .p-card-title, & .p-card-subtitle": {
            textAlign: "center",
        },
        "& .p-card-content": __assign({ height: "50%" }, STYLES_FLEX_CENTER_CENTER),
        "& .p-card-footer": {
            "align-self": "flex-end",
        },
    },
};
