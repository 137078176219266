var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useAccessToken, useUserPreferences, Card, Checkbox, Dialog, Skeleton, ReactRouterLink, Button, useIsMobile, Inplace, InplaceContent, InplaceDisplay, } from "../../../../../projectdiction-common-react";
import { getReadingItem, getWordList, searchText,
// @ts-ignore
 } from "@projectdiction/api-helper";
import ReadingItemSection from "./ReadingItemSection";
import ReadingItemClickableParagraph from "./Paragraph/ReadingItemClickableParagraph";
import AddToDictionaryPopUp from "../Dictionary/AddToDictionaryPopUp";
function ReadingItemTextCard(_a) {
    var readingItemId = _a.readingItemId, dictionaryId = _a.dictionaryId, setDictionaryId = _a.setDictionaryId, showDictionary = _a.showDictionary, setShowDictionary = _a.setShowDictionary, parentViewUpdater = _a.parentViewUpdater, updateParentView = _a.updateParentView, toast = _a.toast;
    var _b = React.useState(), readingItem = _b[0], setReadingItem = _b[1];
    var _c = React.useState([]), searchResults = _c[0], setSearchResults = _c[1];
    var _d = React.useState([]), checkSearchResults = _d[0], setCheckSearchResults = _d[1];
    var token = useAccessToken();
    var _e = React.useState(false), dialogVisible = _e[0], setDialogVisible = _e[1];
    var _f = React.useState(""), selectedWord = _f[0], setSelectedWord = _f[1];
    var _g = React.useState(false), highlightPOS = _g[0], setHighlightPOS = _g[1];
    var _h = React.useState(false), showParallelText = _h[0], setShowParallelText = _h[1];
    var _j = React.useState(true), shouldHighlightWords = _j[0], setShouldHighlightWords = _j[1];
    var userPreferences = useUserPreferences();
    var isMobile = useIsMobile();
    React.useEffect(function () {
        function process() {
            return __awaiter(this, void 0, void 0, function () {
                var response, readingItem, texts, searchResponse, checkDictionarySearchResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getReadingItem(token, readingItemId)];
                        case 1:
                            response = _a.sent();
                            readingItem = response.data.readingItem;
                            setReadingItem(readingItem);
                            if (!dictionaryId) {
                                setDictionaryId(readingItem.checkDictionary);
                            }
                            if (!(!highlightPOS && shouldHighlightWords && userPreferences)) return [3 /*break*/, 6];
                            texts = readingItem.text.map(function (section) { return section.content; });
                            return [4 /*yield*/, getWordList(token, {
                                    dictionaryId: readingItem.dictionary,
                                })];
                        case 2:
                            response = _a.sent();
                            return [4 /*yield*/, searchText(texts, response.data.words, userPreferences.languages.studyLanguage)];
                        case 3:
                            searchResponse = _a.sent();
                            setSearchResults(searchResponse);
                            return [4 /*yield*/, getWordList(token, {
                                    dictionaryId: readingItem.checkDictionary,
                                })];
                        case 4:
                            response = _a.sent();
                            return [4 /*yield*/, searchText(texts, response.data.words, userPreferences.languages.studyLanguage)];
                        case 5:
                            checkDictionarySearchResponse = _a.sent();
                            setCheckSearchResults(checkDictionarySearchResponse);
                            _a.label = 6;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
        try {
            process();
        }
        catch (error) {
            console.log("error", error);
        }
    }, [
        readingItemId,
        token,
        highlightPOS,
        shouldHighlightWords,
        userPreferences,
        parentViewUpdater,
    ]);
    if (!readingItem) {
        return (_jsx(Card, __assign({ className: "w-full h-full shadow-2" }, { children: _jsx(Skeleton, { width: "90%", height: "90%" }) })));
    }
    function wordClicked(word) {
        setSelectedWord(word);
        setDialogVisible(true);
    }
    var footer = (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: [_jsx(Checkbox, { inputId: "highlightWords", checked: shouldHighlightWords, onChange: function () {
                            setShouldHighlightWords(!shouldHighlightWords);
                        } }), _jsx("label", __assign({ htmlFor: "highlightWords" }, { children: "Highlight Known Words" }))] })), _jsxs("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: [_jsx(Checkbox, { inputId: "highlightPOS", checked: highlightPOS, onChange: function () {
                            setHighlightPOS(!highlightPOS);
                        } }), _jsx("label", __assign({ htmlFor: "highlightPOS" }, { children: "Highlight POS" }))] })), _jsxs("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: [_jsx(Checkbox, { inputId: "showParallelText", checked: showParallelText, onChange: function () { return setShowParallelText(!showParallelText); } }), _jsx("label", __assign({ htmlFor: "showParallelText" }, { children: "Show Parallel Text" }))] })), !isMobile && (_jsxs("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: [_jsx(Checkbox, { inputId: "showDictionary", checked: showDictionary, onChange: function () {
                            setShowDictionary(!showDictionary);
                        } }), _jsx("label", __assign({ htmlFor: "showDictionary" }, { children: "Show Dictionary" }))] }))), showDictionary && (_jsxs("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: [_jsx(Checkbox, { inputId: "showCheckDictionary", checked: dictionaryId === readingItem.checkDictionary, onChange: function () {
                            if (dictionaryId === readingItem.checkDictionary) {
                                setDictionaryId(readingItem.dictionary);
                            }
                            else if (dictionaryId === readingItem.dictionary) {
                                setDictionaryId(readingItem.checkDictionary);
                            }
                        } }), _jsx("label", __assign({ htmlFor: "showCheckDictionary" }, { children: "Show Course Dictionary" }))] }))), readingItem.source && (_jsx("div", __assign({ className: "flex align-items-center justify-content-center gap-2" }, { children: _jsx(ReactRouterLink, __assign({ to: readingItem.source, target: "_blank" }, { children: _jsx(Button, { icon: "pi pi-external-link", label: "Source", size: "small" }) })) })))] }));
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, __assign({ header: "Add to dictionary", visible: dialogVisible, style: { width: isMobile ? "100vw" : "50vw" }, onHide: function () { return setDialogVisible(false); }, dismissableMask: true, 
                // @ts-ignore
                position: isMobile && "bottom" }, { children: _jsx(AddToDictionaryPopUp, { readingItemId: readingItemId, word: selectedWord, setDialogVisible: setDialogVisible, toast: toast, updateParentView: updateParentView }) })), _jsx(Card, __assign({ title: _jsx(ReadingItemClickableParagraph, { text: readingItem.title, updateParentView: updateParentView, knownWords: searchResults, knownWordsUser: checkSearchResults, highlightPOS: highlightPOS, showParallelText: showParallelText, highlightKnownWords: shouldHighlightWords, toast: toast, wordClicked: wordClicked }), subTitle: readingItem.subtitle && (_jsx(ReadingItemClickableParagraph, { text: readingItem.subtitle, updateParentView: updateParentView, knownWords: searchResults, knownWordsUser: checkSearchResults, highlightPOS: highlightPOS, showParallelText: showParallelText, highlightKnownWords: shouldHighlightWords, toast: toast, wordClicked: wordClicked })), footer: !isMobile ? (footer) : (_jsxs(Inplace, __assign({ closable: true, pt: {
                        root: { className: "w-full flex justify-content-center" },
                        display: { className: "" },
                        content: {
                            className: "w-full flex text-xs",
                        },
                    } }, { children: [_jsx(InplaceDisplay, { children: _jsx(Button, { label: "Settings", icon: "pi pi-cog", size: "small" }) }), _jsx(InplaceContent, { children: _jsx("div", __assign({ className: "flex gap-2 overflow-auto", style: { flex: "1 1 0" } }, { children: footer })) })] }))), pt: {
                    root: { className: "card-root-with-header-footer shadow-2" },
                    body: { className: "" },
                    content: {
                        className: "flex flex-wrap",
                        style: { height: "" },
                    },
                    title: { className: "w-full flex justify-content-center" },
                    subTitle: { className: "w-full flex justify-content-center" },
                    footer: { className: "flex justify-content-between w-full gap-4" },
                } }, { children: readingItem.text.map(function (text, index) {
                    return (_jsx(ReadingItemSection, { headingLevel: text.headingLevel, heading: text.heading, content: text.content, updateParentView: updateParentView, knownWords: searchResults, knownWordsUser: checkSearchResults, highlightPOS: highlightPOS, showParallelText: showParallelText, highlightKnownWords: shouldHighlightWords, toast: toast, wordClicked: wordClicked }, index));
                }) }))] }));
}
export default ReadingItemTextCard;
