var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReadingItemWordSpan from "../ReadingItemWordSpan";
import { splitIntoWords,
// @ts-ignore
 } from "@projectdiction/common-react";
function Bar(_a) {
    var words = _a.words, highlights = _a.highlights, wordClicked = _a.wordClicked, highlightKnownWords = _a.highlightKnownWords;
    return (_jsx("div", __assign({ className: "w-full sm:w-6 sm:ml-auto sm:mr-auto flex flex-wrap" }, { children: words.map(function (word, index) {
            if (highlightKnownWords) {
                highlights.forEach(function (highlight) {
                    highlight.words.forEach(function (highlightWord) {
                        if (typeof word !== "string") {
                            return;
                        }
                        if (word.toLowerCase().includes(highlightWord.toLowerCase()) &&
                            Math.abs(word.length - highlightWord.length) < 3) {
                            word = _jsx("b", __assign({ className: highlight.color }, { children: word }));
                        }
                    });
                });
            }
            return (_jsx(ReadingItemWordSpan, { word: word, wordClicked: wordClicked }, index));
        }) })));
}
export default function ParagraphHighlightKnownWords(_a) {
    var text = _a.text, knownWords = _a.knownWords, knownWordsUser = _a.knownWordsUser, highlightKnownWords = _a.highlightKnownWords, wordClicked = _a.wordClicked;
    var words = splitIntoWords(text);
    return (_jsx(Bar, { words: words, highlights: [
            { words: knownWords, color: "bg-yellow-200" },
            { words: knownWordsUser, color: "bg-blue-200" },
        ], highlightKnownWords: highlightKnownWords, wordClicked: wordClicked }, knownWords.length + knownWordsUser.length));
}
