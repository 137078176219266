var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";
import ReadingListViewItemDictionary from "./Sections/Dictionary/ReadingListViewItemDictionary";
import ReadingItemTextCard from "./Sections/Reading/ReadingItemTextCard";
import LayoutLeftRight from "./LayoutLeftRight";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
export function ReadingListViewItem(_a) {
    var readingItemId = _a.readingItemId;
    var _b = React.useState(), dictionaryId = _b[0], setDictionaryId = _b[1];
    var _c = React.useState(true), showDictionary = _c[0], setShowDictionary = _c[1];
    var t = useTranslation().t;
    var _d = React.useState(), viewUpdater = _d[0], setViewUpdater = _d[1];
    var toast = React.useRef();
    var updateView = function () {
        setViewUpdater(!viewUpdater);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Card, __assign({ pt: {
                    root: { className: "card-root-with-header-footer p-0" },
                    body: { className: "p-0" },
                    title: { className: "" },
                    subTitle: { className: "" },
                    content: {
                        className: "p-0",
                    },
                } }, { children: _jsx(LayoutLeftRight, __assign({ rightLabel: "Dictionary", rightIcon: "pi pi-book", right: dictionaryId ? (_jsx(ReadingListViewItemDictionary, { readingItemId: readingItemId, dictionaryId: dictionaryId, parentViewUpdater: viewUpdater, updateParentView: updateView }, dictionaryId)) : (_jsx(Skeleton, { className: "p-mb-2 h-full w-full", style: { borderRadius: "0.5rem" } })), showRight: showDictionary }, { children: _jsx(ReadingItemTextCard, { readingItemId: readingItemId, dictionaryId: dictionaryId, setDictionaryId: setDictionaryId, showDictionary: showDictionary, setShowDictionary: setShowDictionary, parentViewUpdater: viewUpdater, updateParentView: updateView, toast: toast }) })) })), _jsx(Toast, { ref: toast })] }));
}
