export function checkForNullOrUndefinedOrEmptyString() {
    var array = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        array[_i] = arguments[_i];
    }
    var nullOrUndefinedOrEmptyString = [];
    array.forEach(function (x) {
        if (x === "" || x === undefined || x === null) {
            nullOrUndefinedOrEmptyString.push(x);
        }
    });
    return nullOrUndefinedOrEmptyString;
}
export function saveToLocalStorage(name, object) {
    localStorage.setItem(name, JSON.stringify(object));
}
export function getFromLocalStorage(name) {
    var value = localStorage.getItem(name);
    if (value) {
        return JSON.parse(value);
    }
    else {
        return value;
    }
}
export function deleteFromLocalStorage(name) {
    if (doesExistInLocalStorage(name)) {
        localStorage.removeItem(name);
    }
}
export function doesExistInLocalStorage(name) {
    return localStorage.getItem(name) !== null;
}
export function removeIndexFromArray(array, index) {
    return array.slice(0, index).concat(array.slice(index + 1));
}
export function splitIntoWords(paragraph) {
    return paragraph.split(/[ \n ]/);
}
export function parseHtmlToContentSections(htmlString) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, "text/html");
    var sections = [];
    var currentLevel = 0;
    var currentHeading = "";
    var currentParagraphs = []; // Array to store paragraphs
    var elements = doc.body.childNodes;
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element.nodeType === Node.ELEMENT_NODE) {
            // @ts-ignore
            var tagName = element.tagName.toLowerCase();
            if (tagName === "h1" ||
                tagName === "h2" ||
                tagName === "h3" ||
                tagName === "h4" ||
                tagName === "h5" ||
                tagName === "h6") {
                // New heading (end the previous section if any)
                if (currentParagraphs.length > 0) {
                    sections.push({
                        headingLevel: currentLevel,
                        heading: currentHeading,
                        content: currentParagraphs, // Assign the array of paragraphs
                    });
                    currentParagraphs = []; // Reset for the next section
                }
                currentLevel = parseInt(tagName.slice(1));
                currentHeading = element.textContent.trim();
            }
            else if (tagName === "p") {
                // Paragraph
                if (element.textContent.trim()) {
                    // Check if it's not just whitespace
                    currentParagraphs.push(element.textContent.trim());
                }
            }
            else {
                // Ignore other elements for now
                console.warn("Ignoring unsupported element: ".concat(tagName));
            }
        }
        else if (element.nodeType === Node.TEXT_NODE) {
            // Text content
            if (element.textContent.trim()) {
                // Check if it's not just whitespace
                currentParagraphs.push(element.textContent.trim());
            }
        }
    }
    // Add the last section (or the leading paragraphs with no heading)
    if (currentParagraphs.length > 0) {
        sections.push({
            headingLevel: currentLevel || 0,
            heading: currentHeading || "",
            content: currentParagraphs,
        });
    }
    return sections;
}
