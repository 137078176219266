import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import TranslatedParagraph from "./TranslatedParagraph";
export default function ParallelTextSwitch(_a) {
    var text = _a.text, showParallelText = _a.showParallelText, ParagraphToRender = _a.ParagraphToRender;
    if (showParallelText) {
        return (_jsxs(_Fragment, { children: [ParagraphToRender, _jsx(TranslatedParagraph, { text: text })] }));
    }
    return ParagraphToRender;
}
