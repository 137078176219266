import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ParagraphHighlightKnownWords from "./ParagraphHighlightKnownWords";
import ParagraphHiglightPOS from "./ParagraphHiglightPOS";
import ParallelTextSwitch from "./ParallelTextSwitch";
export default function ReadingItemClickableParagraph(_a) {
    var text = _a.text, updateParentView = _a.updateParentView, knownWords = _a.knownWords, knownWordsUser = _a.knownWordsUser, highlightKnownWords = _a.highlightKnownWords, _b = _a.highlightPOS, highlightPOS = _b === void 0 ? false : _b, _c = _a.showParallelText, showParallelText = _c === void 0 ? false : _c, toast = _a.toast, wordClicked = _a.wordClicked;
    if (!highlightPOS) {
        return (_jsx(ParallelTextSwitch, { text: text, showParallelText: showParallelText, ParagraphToRender: _jsx(ParagraphHighlightKnownWords, { text: text, knownWords: knownWords, knownWordsUser: knownWordsUser, highlightKnownWords: highlightKnownWords, wordClicked: wordClicked }) }));
    }
    return (_jsx(ParallelTextSwitch, { text: text, showParallelText: showParallelText, ParagraphToRender: _jsx(ParagraphHiglightPOS, { text: text, wordClicked: wordClicked }) }));
}
