// @ts-ignore
import React from "react";
import { singletonHook } from "react-singleton-hook";
import LOCAL_STORAGE_ITEMS from "../static_data/localStorageItems";
import { doesExistInLocalStorage } from "../helperFunctions";
import { jwtDecode } from "jwt-decode";
var init;
if (doesExistInLocalStorage(LOCAL_STORAGE_ITEMS.TOKEN)) {
    var token = localStorage.getItem(LOCAL_STORAGE_ITEMS.TOKEN);
    token = jwtDecode(token);
    init = token.exp > Date.now() / 1000;
}
var globalSetUserLoggedIn = function () {
    throw new Error("useUserLoggedIn is singleton");
};
function useUserLoggedInImpl() {
    var _a = React.useState(init), userLoggedIn = _a[0], setUserLoggedIn = _a[1];
    // @ts-ignore
    globalSetUserLoggedIn = setUserLoggedIn;
    return userLoggedIn;
}
export var setUserLoggedInState = function (loggedIn) {
    // @ts-ignore
    return globalSetUserLoggedIn(loggedIn);
};
export var useUserLoggedIn = singletonHook(init, useUserLoggedInImpl);
