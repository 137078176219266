var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useIsMobile } from "../../../hooks";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
export default function LayoutLeftRight(_a) {
    var left = _a.left, right = _a.right, children = _a.children, showLeft = _a.showLeft, showRight = _a.showRight, leftLabel = _a.leftLabel, leftIcon = _a.leftIcon, rightLabel = _a.rightLabel, rightIcon = _a.rightIcon;
    var isMobile = useIsMobile();
    var _b = React.useState(false), showLeftDialog = _b[0], setShowLeftDialog = _b[1];
    var _c = React.useState(false), showRightDialog = _c[0], setShowRightDialog = _c[1];
    if (!isMobile) {
        return (_jsxs("div", __assign({ className: "flex w-full h-full gap-4" }, { children: [left && showLeft && (_jsx("div", __assign({ className: "flex flex-column w-3 h-full" }, { children: left }))), _jsx("div", __assign({ className: "flex flex-column h-full flex-1" }, { children: children })), right && showRight && (_jsx("div", __assign({ className: "flex flex-column w-3 h-full" }, { children: right })))] })));
    }
    var position = "right";
    var dialogProps = {
        fullScreen: true,
        position: position,
    };
    return (_jsxs(_Fragment, { children: [_jsx(Card, __assign({ className: "card-root-with-header-footer", footer: _jsxs("div", __assign({ className: "flex justify-content-center gap-2" }, { children: [left && (_jsx(Button, { label: leftLabel, icon: leftIcon, onClick: function () { return setShowLeftDialog(true); } })), right && (_jsx(Button, { label: rightLabel, icon: rightIcon, onClick: function () { return setShowRightDialog(true); } }))] })) }, { children: children })), ";", left && (_jsx(Sidebar, __assign({}, dialogProps, { visible: showLeftDialog, onHide: function () { return setShowLeftDialog(false); } }, { children: left }))), right && (_jsx(Sidebar, __assign({}, dialogProps, { visible: showRightDialog, onHide: function () { return setShowRightDialog(false); } }, { children: right })))] }));
}
