var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { NavigationFooter } from "./NavigationFooter";
import { Card } from "../../../primereact-components";
export function NavigationCard(_a) {
    var title = _a.title, description = _a.description, route = _a.route, label = _a.label;
    return (_jsx(Card, __assign({ title: title, className: "card-root-with-header-footer h-15rem w-15rem", footer: _jsx(NavigationFooter, { route: route, label: label }) }, { children: description })));
}
