var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createUseStyles, useNavigate } from "../../../common-libraries";
import { Button } from "../../../primereact-components";
// @ts-ignore
import { useTranslation } from "react-i18next";
var useStyles = createUseStyles({
    root: {},
});
export var NavigationFooter = function (_a) {
    var route = _a.route, label = _a.label;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var classes = useStyles();
    return (_jsx("div", __assign({ className: "".concat(classes.root, " flex") }, { children: _jsx(Button, { label: label, disabled: !route, onClick: function () {
                if (!route)
                    return;
                if (typeof route === "string") {
                    navigate(route);
                    return;
                }
                route();
            } }) })));
};
