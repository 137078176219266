var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReadingItemClickableParagraph from "./Paragraph/ReadingItemClickableParagraph";
import { createUseStyles, useIsMobile, } from "../../../../../projectdiction-common-react";
var useStyles = createUseStyles({
    paragraph: {
        width: "40rem",
    },
});
export default function ReadingItemSection(_a) {
    var headingLevel = _a.headingLevel, heading = _a.heading, content = _a.content, updateParentView = _a.updateParentView, _b = _a.knownWords, knownWords = _b === void 0 ? [] : _b, _c = _a.knownWordsUser, knownWordsUser = _c === void 0 ? [] : _c, highlightKnownWords = _a.highlightKnownWords, _d = _a.highlightPOS, highlightPOS = _d === void 0 ? false : _d, _e = _a.showParallelText, showParallelText = _e === void 0 ? false : _e, toast = _a.toast, wordClicked = _a.wordClicked;
    var classes = useStyles();
    var isMobile = useIsMobile();
    var generateHeading = function (headingLevel, heading) {
        var className = "w-full flex justify-content-center";
        switch (headingLevel) {
            case 0:
                return null;
            case 2:
                return _jsx("h2", __assign({ className: className }, { children: heading }));
            case 3:
                return _jsx("h3", __assign({ className: className }, { children: heading }));
            case 4:
                return _jsx("h4", __assign({ className: className }, { children: heading }));
            case 5:
                return _jsx("h5", __assign({ className: className }, { children: heading }));
            case 6:
                return _jsx("h6", __assign({ className: className }, { children: heading }));
            default:
                return _jsx("h1", __assign({ className: className }, { children: heading }));
        }
    };
    return (_jsxs("div", __assign({ className: "flex flex-column w-full align-items-center" }, { children: [generateHeading(headingLevel, _jsx(ReadingItemClickableParagraph, { text: heading, updateParentView: updateParentView, knownWords: knownWords, knownWordsUser: knownWordsUser, highlightPOS: highlightPOS, showParallelText: showParallelText, highlightKnownWords: highlightKnownWords, toast: toast, wordClicked: wordClicked })), content.map(function (paragraph, index) {
                return (_jsx(ReadingItemClickableParagraph, { text: paragraph, updateParentView: updateParentView, knownWords: knownWords, knownWordsUser: knownWordsUser, highlightPOS: highlightPOS, showParallelText: showParallelText, highlightKnownWords: highlightKnownWords, toast: toast, wordClicked: wordClicked }, index));
            })] })));
}
